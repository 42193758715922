import { Component } from '@angular/core';

@Component({
    selector: 'oha-not-found',
    styleUrls: ['./not-found.component.scss'],
    templateUrl: './not-found.component.html',
})
export class NotFoundComponent {
    constructor() {}
}
