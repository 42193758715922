import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'oha-internal-server-error',
    templateUrl: './internal-server-error.component.html',
    styleUrls: ['./internal-server-error.component.scss'],
})
export class InternalServerErrorComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
