<nz-result
    nzStatus="500"
    nzTitle="500"
    nzSubTitle="{{ 'MISCELLANEOUS.500' | translate }}"
>
    <div nz-result-extra>
        <button nz-button nzType="primary" routerLink="/">
            {{ 'MISCELLANEOUS.BUTTON' | translate }}
        </button>
    </div>
</nz-result>
