import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import vi from '@angular/common/locales/vi';
import {
    HttpClientModule,
    HttpClient,
    HTTP_INTERCEPTORS,
    HttpRequest,
} from '@angular/common/http';
import { customCurrencyConfig } from '@common/constant/custom-currency-config.constant';
import { HandleHttpSubscribe } from '@common/helpers/handle-http-subscribe';
import { ConfirmModalService } from '@common/services/confirm-modal.service';
import { TokenInterceptor } from '@config/interceptors/token.interceptor';

import { en_US, NZ_DATE_LOCALE, NZ_I18N } from 'ng-zorro-antd/i18n';
import { vi_VN } from 'ng-zorro-antd/i18n';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MiscellaneousModule } from '@features/miscellaneous/miscellaneous.module';
import { LocalStorageHelper } from '@common/helpers/local-storage.helper';
import { FilterHelper } from '@common/helpers/filter.heper';
import {
    AUTH_TOKEN_INTERCEPTOR_FILTER,
    exceptURL,
} from '@config/auth-token-filter.config';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { enUS } from 'date-fns/locale';
// Firebase services + environment module
import { environment } from '@environment/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FirebaseTokenService } from '@features/services/firebase-token.service';
import { DeviceDetectorService } from 'ngx-device-detector';

registerLocaleData(vi);

// tslint:disable-next-line: typedef
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

// tslint:disable-next-line: typedef
export function filterInterceptorRequest(req: HttpRequest<any>) {
    return exceptURL.some((url) => req.url.includes(url));
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MiscellaneousModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        NzModalModule,
        NzNotificationModule,
        AngularSvgIconModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgxCurrencyModule.forRoot(customCurrencyConfig),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule.enablePersistence(),
        AngularFireStorageModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: AUTH_TOKEN_INTERCEPTOR_FILTER,
            useValue: filterInterceptorRequest,
        },
        {
            provide: NZ_I18N,
            useFactory: (localId: string) => {
                switch (localId) {
                    case 'en':
                        return en_US;
                    default:
                        return vi_VN;
                }
            },
        },
        {
            provide: LOCALE_ID,
            useValue: 'vi',
        },
        {
            provide: NZ_DATE_LOCALE,
            useValue: enUS,
        },
        ConfirmModalService,
        LocalStorageHelper,
        FilterHelper,
        HandleHttpSubscribe,
        FirebaseTokenService,
        DeviceDetectorService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
