import { Injectable } from '@angular/core';
import { BaseAPIService } from '@common/services/base-api.service';
import { Observable } from 'rxjs';
import { API_URL } from '@config/api-url.config';
import { FirebaseTokenDto } from '@features/services/dto/firebase-token.dto';

@Injectable()
export class FirebaseTokenService {
    constructor(private readonly http: BaseAPIService) {}

    /**
     * Add new member firebase token
     */
    addMemberFirebaseToken(dto: FirebaseTokenDto): Observable<any> {
        const url = API_URL.MEMBER_FIREBASE_TOKEN.CONTROLLER;
        return this.http.post(url, dto);
    }

    /**
     * Remove member firebase token
     */
    removeMemberFirebaseToken(token: string): Observable<any> {
        const url = API_URL.MEMBER_FIREBASE_TOKEN.CONTROLLER;
        return this.http.delete(url + '/' + token);
    }
}
