<nz-result
    nzStatus="403"
    nzTitle="403"
    nzSubTitle="{{ 'MISCELLANEOUS.403' | translate }}"
>
    <div nz-result-extra>
        <button nz-button nzType="primary" routerLink="/">
            {{ 'MISCELLANEOUS.BUTTON' | translate }}
        </button>
    </div>
</nz-result>
