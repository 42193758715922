import { CurrencyMaskConfig } from 'ngx-currency/src/currency-mask.config';

export const customCurrencyConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: true,
    decimal: '.',
    precision: 2,
    suffix: ' đ ',
    prefix: '',
    thousands: ',',
    nullable: false,
    min: 0,
};
