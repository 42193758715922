import { Injectable } from '@angular/core';
import { BaseAPIService } from '@common/services/base-api.service';
import { API_URL } from '@config/api-url.config';
import { LOCAL_STORAGE } from '@config/local-storage.config';
import { IMemberProfileRo } from '@features/services/ro/member-profile.ro';
import { UserInfoSideNavModel } from '@navigation/models/user-info-side-nav.model';

@Injectable({ providedIn: 'root' })
export class SideNavService {
    constructor(private http: BaseAPIService) {}

    /**
     * set side-nav user info
     */
    setSideNavUserInfo(data: UserInfoSideNavModel): void {
        localStorage.setItem(LOCAL_STORAGE.USER_INFO, JSON.stringify(data));
        window.dispatchEvent(new Event('storage'));
    }

    /**
     * get side-nav user info
     */
    getSideNavUserInfo(): UserInfoSideNavModel | undefined {
        try {
            return JSON.parse(
                localStorage.getItem(LOCAL_STORAGE.USER_INFO) as any,
            );
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    /**
     * clean user info of side-nav
     */
    cleanSideNavUserInfo(): void {
        localStorage.removeItem(LOCAL_STORAGE.USER_INFO);
        window.dispatchEvent(new Event('storage'));
    }

    /**
     * update user info by memberId
     *
     */
    updateByMemberId(memberId: string): void {
        this.http
            .get<IMemberProfileRo>(`${API_URL.MEMBER.CONTROLLER}/${memberId}`)
            .subscribe({
                next: (value) => {
                    const userInfo: UserInfoSideNavModel = {
                        avatar: value.avatarUrl,
                        name: value.name,
                        role: value.permissionGroup?.type || '',
                        permissionGroupName: value.permissionGroup?.name || '',
                    };
                    this.setSideNavUserInfo(userInfo);
                },
                error: (err) => {
                    console.error(err);
                    this.cleanSideNavUserInfo();
                },
            });
    }
}
