import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { en_US, NzI18nService, vi_VN } from 'ng-zorro-antd/i18n';
import { FirebaseService } from '@features/services/firebase.service';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
    `,
})
export class AppComponent {
    title = 'cht-web';

    constructor(
        private translate: TranslateService,
        private i18n: NzI18nService,
    ) {
        const language = localStorage.getItem('language') || 'vi';
        this.translate.setDefaultLang(language);
        this.translate.use(language);
        switch (language) {
            case 'en':
                this.i18n.setLocale(en_US);
                break;
            default:
                this.i18n.setLocale(vi_VN);
        }
    }
}
