export const API_URL = {
    AUTH: {
        CONTROLLER: 'auth',
        FACEBOOK: 'facebook',
        FACEBOOK_V4: 'facebook-v4',
        REFRESH: 'auth/refresh',
        LOGIN: 'auth/login',
        LOGOUT: 'logout',
        GET_PERMISSIONS: 'auth/get-permissions',
    },
    USER: {
        CONTROLLER: 'user',
        PROFILE: 'profile',
    },
    CUSTOMER: {
        CONTROLLER: 'customer',
        CREATE: 'create',
        UPDATE: 'update',
    },
    ORDER: {
        CONTROLLER: 'order',
        LIST_PENDING: 'list-pending',
        CREATE: 'create',
    },
    COUNTRY: {
        CONTROLLER: 'location',
        GET_LOCATION_IDS: 'get-location-ids',
        GET_COUNTRIES: 'location/countries',
        COUNTRY: 'country',
        CITY: 'city',
        DISTRICT: 'district',
        WARD: 'ward',
    },
    MEMBER: {
        CONTROLLER: 'member',
        GET_WITH_FILTER: 'member/get-with-filter',
        STAFF: 'staff',
        SUPPLIER: 'supplier',
        STATUS: 'status',
        IMPORT: 'import',
        EXPORT: 'export-excel',
        TYPE: 'member/type',
        TYPE_COUNTRY: 'member/type-country',
        TYPE_ACTIVE: 'member/type-active',
        GET_DELIVERY_ADDRESS: 'member/get-delivery-address-by-customer',
        GET_CUSTOMERS_WITHOUT_PROMOTION: 'member/customers-without-promotion',
    },
    MASTER_DATA: {
        CONTROLLER: 'master-data',
        STOREHOUSE: {
            CONTROLLER: 'storehouse',
            GET_BY_CODE_RELATION_POSITION: 'get-by-code-relation-position',
            GET_BY_CODE_RELATION_TRANSPORT: 'get-by-code-relation-transport',
            GET_BY_ID: 'get-by-id',
        },
        STOREHOUSE_POSITION_SETTING: {
            CONTROLLER: 'storehouse-position-setting',
        },
        TRANSPORT_FEE_SETTING: {
            CONTROLLER: 'transport-fee',
            COMBO_SAVE: 'combo-save-data',
        },
        PRODUCT_COLOR: {
            CONTROLLER: 'master-data/product-color',
            DELETE: 'master-data/product-color/delete',
        },
        PRODUCT_SIZE: {
            CONTROLLER: 'master-data/product-size',
        },
        PROMOTION: {
            CONTROLLER: 'master-data/promotion',
        },
        PRODUCT_TYPE: {
            CONTROLLER: 'master-data/product-type',
            TYPE_MASTER: 'master-data/product-type/type-master',
        },
        PRODUCT_CATEGORY: {
            CONTROLLER: 'master-data/product-category',
        },
        CUSTOMER_TYPE: {
            CONTROLLER: 'customer-type',
        },
        DELIVERY_TIME: {
            CONTROLLER: 'delivery-time',
        },
        PRODUCT_QUALITY: {
            CONTROLLER: 'product-quality',
            GET_BY_ID: 'get-by-id',
        },
        PACKING_SPECIFICATION: {
            CONTROLLER: 'master-data/packing-specification',
            GET_ALL_RELATION: 'master-data/packing-specification/relations',
        },
        PRODUCT_UNIT_TYPE: {
            CONTROLLER: 'master-data/product-unit-type',
        },
        PACKING_TYPE: {
            CONTROLLER: 'master-data/packing-type',
            GET_ALL_RELATION: 'master-data/packing-type/relations',
        },
    },
    NOTIFICATION: {
        CONTROLLER: 'notification',
        GET_WITH_ADMIN_FILTER: 'notification/get-with-admin-pagination',
        GET_NOTICE_WITH_ADMIN_FILTER: 'notification/get-notice-with-pagination',
    },
    PROMOTION_NOTICE: {
        CONTROLLER: 'promotion-notice',
        GET_WITH_ADMIN_FILTER: 'promotion-notice/get-with-admin-pagination',
    },
    PERMISSION_MANAGEMENT: {
        CONTROLLER: 'permission-management',
        GET_ALL_PERMISSION_GROUP: 'get-all-permission-group',
        GET_PERMISSION_GROUP_MERGE_PERMISSIONS:
            'permission-group-merge-permissions',
        UPDATE_PERMISSIONS_OF_GROUP: 'update-permissions-of-group',
        GET_PERMISSIONS_AND_OF_PERMISSION_GROUP:
            'permission-and-of-permission-group',
        CREATE_NEW_PERMISSION_GROUP: 'create-permission-group',
        GET_PERMISSION_GROUP_TEMPLATE: 'get-template-permission-group-key',
    },
    UPLOAD_DATA: {
        CONTROLLER: 'upload-data',
        GET_SIGNED_URL: 'getUploadImageUrl',
    },
    PRODUCT: {
        CONTROLLER: 'product',
        PRODUCT_IMAGE: 'product/product-image',
        GET_OF_SUPPLIER: 'product/supplier',
        CLONE_PRODUCT: 'supplier/clone',
        GET_WITH_FILTER: 'product/get-with-filter',
        GET_WITH_ORDERED: 'product/get-with-ordered',
        GET_WITH_SUPPLIER_ORDERED: 'product/supplier',
        GET_WITH_SUPPLIER_IMPORT: 'product/supplier-import',
        GET_ALL_WITH_PAGING: 'product/paging',
        EXPORT: 'export-with-filter',
        IMPORT_AUTO_PRICE: 'import-excel/auto-price',
    },
    CONTAINER_TRUCK: {
        CONTROLLER: 'container-truck',
        GET_WITH_FILTER: 'container-truck/get-with-filter',
    },
    PRE_ORDER: {
        CONTROLLER: 'pre-order',
        COPY: 'pre-order/copy',
        STATUS: 'status',
        GET_WITH_FILTER: 'pre-order/get-with-filter',
        EXPORT_EXCEL: 'pre-order/export-excel',
    },
    SALES_ORDER: {
        CONTROLLER: 'sales-order',
        GET_WITH_FILTER: 'sales-order/get-with-filter',
        GET_RAW: 'sales-order/get-raw',
        UPDATE_PACKING_FEE: 'sales-order/update-parking-fee',
        SETTING_DISCOUNT: 'sales-order/setting-discount',
        GET_HISTORY: 'sales-order/history/get-by-sales-order',
        GET_SALES_ORDER_FOR_RETURNED_ORDER: 'sales-order/get-sale-order-by-id',
        GET_DISTANCE_INFO: 'sales-order/get-distance-info',
        CHECK_ADDRESS: 'sales-order/check-address',
        EXPORT: 'sales-order/export-with-filter',
        NOTE: {
            CONTROLLER: 'sales-order/note/',
            GET_BY_SALE_ID: 'sales-order/note/get-by-sale-id',
        },
        PREPARE: {
            CONTROLLER: 'sales-order/prepare',
            LIST_PREPARE: 'sales-order/prepare/get-list-prepare',
            PRODUCT_PREPARE: 'sales-order/prepare/get-product-prepare',
            ADD_PREPARE: 'sales-order/prepare/create-item-prepare',
            DELETE_PREPARE: 'sales-order/prepare/delete-item-prepare',
            CREATE_PREPARE_DETAIL:
                'sales-order/prepare/create-item-prepare-details',
            UPDATE_PREPARE_DETAIL:
                'sales-order/prepare/update-item-prepare-details',
        },
        GET_BY_ID: 'sales-order/get-by-id',
        PACK: {
            CONTROLLER: 'sales-order/packing',
        },
        RETURNED_CENTRAL_STOREHOUSE: {
            CONTROLLER: 'returned/central-storehouse',
            GET_LIST_CENTRAL_STOREHOUSE:
                'returned/central-storehouse/get-list-central-storehouse',
            GET_LIST_CENTRAL_STOREHOUSE_PRODUCT:
                'returned/central-storehouse/get-product',
            GET_LOT_CODE: 'returned/central-storehouse/get-lot-code',
            DELETE_CENTRAL_STOREHOUSE:
                'returned/central-storehouse/delete-central-storehouse',
            DELETE_CENTRAL_STOREHOUSE_DETAIL:
                'returned/central-storehouse/delete-central-storehouse-detail',
            CREATE_CENTRAL_STOREHOUSE:
                'returned/central-storehouse/create-lot-code',
            CREATE_CENTRAL_STOREHOUSE_DETAIL:
                'returned/central-storehouse/create-central-storehouse-detail',
            UPDATE_CENTRAL_STOREHOUSE_POSITION:
                'returned/central-storehouse/update-central-storehouse-position',
        },
        CHECK_CAN_DELETE_SALES_ORDER_ITEMS:
            'sales-order/sales-order-item/check-can-delete',
    },
    INVENTORY: {
        CONTROLLER: 'inventory',
        GET_PRODUCT: 'inventory/get-product-pre-order',
        LO_LOT_LIST: 'inventory/get-lo-lot-list',
        CREATE_GOODS_RECEIPT: 'inventory/goods-receipt',
        GET_LOT_QTY_REMAINING: 'inventory/get-lot-qty-remaining',
        GET_WITH_FILTER: 'inventory/get-with-filter',
        EXPORT_CANCELLATION: 'inventory/goods-issue/destroy',
        TRANSFER_PROMOTION: 'inventory/promotion',
        GENERATE_LOT_CODE: 'inventory/generate-lot-code',
        EXPORT_TRANSFER: 'inventory/exchange-storehouse',
        GET_PRODUCT_DESTROYED: 'inventory/get-destroyed-products',
        EXPORT_EXCEL: 'inventory/export-excel',
        EXPORT_EXCEL_INVENTORY: 'inventory/export-excel-inventory',
        EXPORT_EXCEL_PRICE_QUOTE: 'inventory/export-excel-price-quote',
        EXPORT_PDF_PRICE_QUOTE: 'inventory/export-pdf-price-quote',
        SET_MIN_QTY: 'inventory/min-purchase-quantity',
        SET_EXPIRE_DATE: 'inventory/expire-date',
    },
    PURCHASE_ORDER: {
        CONTROLLER: 'purchase-order',
        GET_WITH_FILTER: 'purchase-order/get-with-filter',
        STATUS: 'status',
        PACK: {
            CONTROLLER: 'purchase-order/packing',
        },
        CHECK: {
            CONTROLLER: 'purchase-order/check',
        },
        CENTRAL_STOREHOSUE: {
            CONTROLLER: 'purchase-order/central-storehouse',
            GET_LIST_CENTRAL_STOREHOUSE:
                'purchase-order/central-storehouse/get-list-central-storehouse',
            GET_LIST_CENTRAL_STOREHOUSE_PRODUCT:
                'purchase-order/central-storehouse/get-product',
            GET_LIST_CENTRAL_STOREHOUSE_RETURNED_BY_PURCHASE_ORDER:
                'purchase-order/central-storehouse/get-list-central-storehouse-returned',
            GET_LIST_CENTRAL_STOREHOUSE_RETURNED_BY_PURCHASE_ORDER_FOR_EDIT:
                'purchase-order/central-storehouse/get-list-central-storehouse-returned-detail',
            GET_LOT_CODE: 'purchase-order/central-storehouse/get-lot-code',
            DELETE_CENTRAL_STOREHOUSE:
                'purchase-order/central-storehouse/delete-central-storehouse',
            DELETE_CENTRAL_STOREHOUSE_DETAIL:
                'purchase-order/central-storehouse/delete-central-storehouse-detail',
            CREATE_CENTRAL_STOREHOUSE:
                'purchase-order/central-storehouse/create-lot-code',
            CREATE_CENTRAL_STOREHOUSE_DETAIL:
                'purchase-order/central-storehouse/create-central-storehouse-detail',
            UPDATE_CENTRAL_STOREHOUSE_POSITION:
                'purchase-order/central-storehouse/update-central-storehouse-position',
        },
        GET_HISTORY: 'purchase-order/history/get-by-purchase-order',
        GET_SUPPLIERS: 'purchase-order/suppliers',
    },
    RETURNED_ORDER: {
        CONTROLLER: 'returned',
        GET_WITH_FILTER: 'returned/get-with-filter',
    },
    RETURNED_PURCHASE_ORDER: {
        CONTROLLER: 'returned-purchase-order',
        GET_WITH_FILTER: 'returned-purchase-order/get-with-filter',
    },
    COMPENSATION: {
        CONTROLLER: 'compensation',
    },
    CONFIG_CUSTOMER_TYPE: {
        CONTROLLER: 'config-customer-type',
        GET_BY_PRODUCT_ID: 'config-customer-type/get-by-product-id',
    },
    MANAGE_PAID: {
        CONTROLLER: 'money-management',
        PURCHASE_ORDER: 'money-management/purchase-order',
        SALES_ORDER: 'money-management/sales-order',
        PAYMENT: 'money-management/payment',
        PAYMENT_SALES_ORDER: 'money-management/payment-sales-order',
        FOR_CUSTOMER: 'money-management/customer',
        GET_SUM_DEBT_CUSTOMER: 'money-management/get-sum-debt-customer',
        EXPORT_CUSTOMER: 'money-management/customer-export',
        DELETE_PAYMENT: 'money-management/delete-payment',
        UPDATE_PAYMENT: 'money-management/update-payment-sales-order',
    },
    SEASON_FLOWER: {
        CONTROLLER: 'seasonal-flowers',
    },
    PAYMENT: {
        CONTROLLER: 'payment',
        HISTORY: 'payment/history/get-by-payment',
    },
    DASHBOARD: {
        DASHBOARD: 'dashboard',
        SUMMARY: 'dashboard/summary',
    },
    SETTINGS: {
        CONTROLLER: 'settings',
        PRICE_SETTING: 'price-setting',
    },
    PRICE_CONFIG: {
        CONTROLLER: 'price-config',
    },
    PRICE_CONFIG_PRODUCT: {
        CONTROLLER: 'price-config-product',
        GET_BY_PRICE_CONFIG_ID: 'price-config-product/price-config',
        DELETE_PRODUCTS: 'price-config-product/delete-products',
        GET_PRODUCT_IGNORE_CONFIG: 'price-config-product/products-ignore',
    },
    PROMOTION_LIST: {
        CONTROLLER: 'customer-promotion',
        GET_WITH_FILTER: 'customer-promotion/get-with-pagination',
        GET_CUSTOMER: 'customer-promotion/get-members-with-pagination',
        ADD_CUSTOMER: 'customer-promotion/members',
        EDIT_CUSTOMER: 'customer-promotion/members/update',
        REMOVE_CUSTOMER: 'customer-promotion/members/delete',
        CUSTOMER_PROMOTION_SALE_ORDER: 'customer-promotion-sale-order',
    },
    MANAGE_EXPENSE: {
        CONTROLLER: 'expense-management',
    },
    MEMBER_FIREBASE_TOKEN: {
        CONTROLLER: 'member/firebase-token',
    },
    INVENTORY_DISCOUNT: {
        CONTROLLER: 'inventory-discount',
        GET_BY_DISCOUNT_CODE: 'inventory-discount/get-by-discount-code',
    },
    AUTO_PRICE_CONFIG: {
        CONTROLLER: 'auto-price',
        IMPORT_EXCEL: 'auto-price/import-excel',
        EXPORT_EXCEL: 'auto-price/export-excel',
        CALCULATE_PRICE: 'auto-price/calculator',
    },
};
