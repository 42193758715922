import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { NAVIGATION } from '@config/navigation.config';
import { AuthService } from '@features/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(): boolean {
        if (this.authService.isLoggedIn) {
            return true;
        }

        this.router.navigate([NAVIGATION.AUTH]);
        return false;
    }

    canActivateChild(): boolean {
        return this.canActivate();
    }
}
