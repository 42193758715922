export const LOCAL_STORAGE = {
    ACCESS_TOKEN: 'access_token',
    REFRESH_TOKEN: 'refresh_token',
    EXPIRED_IN: 'expiredIn',
    USER_EMAIL: 'user_email',
    USER_ID: 'user_id',
    PERMISSION_DATA: 'permission_data',
    DATA_LOCATION: 'data_location',
    USER_INFO: 'user-info',
    FIREBASE_TOKEN: 'firebase_token',
    NEW_NOTIFICATION: 'new_notification',
};
