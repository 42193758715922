import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BaseAPIService } from '@common/services/base-api.service';
import { SideNavService } from '@common/services/side-nav.service';
import { API_URL } from '@config/api-url.config';
import { LOCAL_STORAGE } from '@config/local-storage.config';
import { LoginDto } from '@features/auth/dto/login.dto';
import { MemberInfoJwtModel } from '@features/auth/model/member-info-jwt.model';
import { PermissionModel } from '@features/auth/model/permission.model';
import { TokenRo } from '@features/auth/ro/token.ro';
import { UserInfoSideNavModel } from '@navigation/models/user-info-side-nav.model';
import { Observable, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILoginRo } from './ro/login.ro';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private router: Router,
        private http: BaseAPIService,
        private sideNavService: SideNavService,
    ) {}

    /**
     * gọi api đăng nhập
     * gọi api get permissions
     * lưu permissions, tokens vào local storage
     * logout nếu không có permissions
     * @param loginDto - Login dto
     */
    login(loginDto: LoginDto): Observable<any> {
        return this.http.post<TokenRo>(`${API_URL.AUTH.LOGIN}`, loginDto).pipe(
            switchMap((res) => {
                // lưu thông tin token của user vào localstorage
                this.saveToken(
                    LOCAL_STORAGE.ACCESS_TOKEN,
                    res.accessToken ?? '',
                );
                this.saveToken(
                    LOCAL_STORAGE.REFRESH_TOKEN,
                    res.refreshToken ?? '',
                );

                // fetch permissions của user và cập nhật vào local storage
                return this.fetchPermissions().pipe(
                    tap((permissions) => {
                        if (!permissions.length) {
                            // nếu không có bất kỳ permissions nào >> logout
                            this.logout();
                        }
                    }),
                );
            }),
        );
    }

    requestAccessToken(): Observable<any> {
        return this.http.get<ILoginRo>(
            `${API_URL.AUTH.REFRESH}/?refreshToken=${this.getRefreshToken}`,
        );
    }

    logout(): void {
        localStorage.clear();
        this.router.navigate(['auth/login']).then();
    }

    // Getter
    get isLoggedIn(): boolean {
        const token = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
        return !!token;
    }

    get getToken(): string | null {
        return localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN) ?? null;
    }

    get getRefreshToken(): string | null {
        return localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN) ?? null;
    }

    get isAccessTokenExpired(): boolean {
        return this.checkTokenExpire(LOCAL_STORAGE.ACCESS_TOKEN);
    }

    get isRefreshTokenExpired(): boolean {
        return this.checkTokenExpire(LOCAL_STORAGE.REFRESH_TOKEN);
    }

    // Method
    public saveToken(tokenName: string, tokenValue: string): void {
        localStorage.setItem(tokenName, tokenValue);
        if (tokenName === LOCAL_STORAGE.ACCESS_TOKEN) {
            const helper = new JwtHelperService();
            const infoBase64: MemberInfoJwtModel | null =
                helper.decodeToken(tokenValue);
            if (infoBase64) {
                // tslint:disable-next-line:no-unused-expression
                infoBase64.userId &&
                    localStorage.setItem(
                        LOCAL_STORAGE.USER_ID,
                        infoBase64.userId,
                    );
                // tslint:disable-next-line:no-unused-expression
                infoBase64.userEmail &&
                    localStorage.setItem(
                        LOCAL_STORAGE.USER_EMAIL,
                        infoBase64.userEmail,
                    );
                const userInfo: UserInfoSideNavModel = {
                    avatar: infoBase64.userInfo?.avatar,
                    name: infoBase64.userInfo?.name,
                    role: infoBase64.userInfo?.role,
                    permissionGroupName:
                        infoBase64.userInfo?.permissionGroupName,
                };
                this.sideNavService.setSideNavUserInfo(userInfo);
            }
        }
    }

    protected checkTokenExpire(tokenName: string): boolean {
        const token = localStorage.getItem(tokenName);
        if (token) {
            const helper = new JwtHelperService();
            return helper.isTokenExpired(token);
        }
        return true;
    }

    /**
     * APi get permissions
     *
     * Lưu permissions vào localstorage
     *
     */
    public fetchPermissions(): Observable<PermissionModel[]> {
        localStorage.removeItem(LOCAL_STORAGE.PERMISSION_DATA);
        return this.http
            .get<PermissionModel[]>(`${API_URL.AUTH.GET_PERMISSIONS}`)
            .pipe(
                map((permissions) => {
                    localStorage.setItem(
                        LOCAL_STORAGE.PERMISSION_DATA,
                        JSON.stringify(permissions || []),
                    );
                    return permissions || [];
                }),
            );
    }
}
