import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { LocalStorageHelper } from '@common/helpers/local-storage.helper';
import { LOCAL_STORAGE } from '@config/local-storage.config';

@Injectable({
    providedIn: 'root',
})
export class BaseAPIService {
    baseUrl: string = environment.BE_URL;
    // headers = new HttpHeaders({
    //     'Content-Type': 'application/json; charset=utf-8',
    //     Authorization: 'Bearer ' + localStorage.getItem('access_token'),
    // });
    headers: HttpHeaders;

    constructor(
        public http: HttpClient,
        private localStorageHelper: LocalStorageHelper,
    ) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            Authorization:
                'Bearer ' +
                this.localStorageHelper.get(LOCAL_STORAGE.ACCESS_TOKEN),
        });
    }

    get<T>(url: string, headers?: HttpHeaders): Observable<T> {
        return this.http.get<T>(`${this.baseUrl}/${url}`, {
            headers: headers ?? this.headers,
        });
    }

    getBlob(url: string): Observable<Blob> {
        return this.http.get<Blob>(`${this.baseUrl}/${url}`, {
            headers: this.headers,
            responseType: 'blob' as any,
        });
    }

    postBlob<T>(
        url: string,
        body?: object,
        headers?: HttpHeaders,
    ): Observable<T> {
        return this.http.post<T>(`${this.baseUrl}/${url}`, body ?? {}, {
            headers: headers ?? this.headers,
            responseType: 'blob' as any,
        });
    }

    getXML(url: string): Observable<any> {
        const headersXML = new HttpHeaders({ 'Content-Type': 'text/xml' });
        headersXML.set('Accept', 'text/xml');
        headersXML.set('Content-Type', 'text/xml');
        return this.http.get(`${url}`, {
            headers: headersXML,
            responseType: 'text',
        });
    }

    post<T>(url: string, body?: object, headers?: HttpHeaders): Observable<T> {
        return this.http.post<T>(`${this.baseUrl}/${url}`, body ?? {}, {
            headers: headers ?? this.headers,
        });
    }

    put<T>(url: string, body?: object, headers?: HttpHeaders): Observable<T> {
        return this.http.put<T>(`${this.baseUrl}/${url}`, body ?? {}, {
            headers: headers ?? this.headers,
        });
    }

    patch<T>(url: string, body?: object, headers?: HttpHeaders): Observable<T> {
        return this.http.patch<T>(`${this.baseUrl}/${url}`, body ?? {}, {
            headers: headers ?? this.headers,
        });
    }

    postFormData<T>(url: string, body: object): Observable<T> {
        const formData = new FormData();
        for (const [key, value] of Object.entries(body)) {
            if (value) {
                formData.append(key, value);
            }
        }
        return this.http.post<T>(`${this.baseUrl}/${url}`, formData, {
            headers: new HttpHeaders(),
        });
    }

    putFormData<T>(url: string, body: object): Observable<T> {
        const formData = new FormData();
        for (const [key, value] of Object.entries(body)) {
            if (value) {
                formData.append(key, value);
            }
        }
        return this.http.put<T>(`${this.baseUrl}/${url}`, formData, {
            headers: new HttpHeaders(),
        });
    }

    delete<T>(url: string): Observable<T> {
        return this.http.delete<T>(`${this.baseUrl}/${url}`);
    }

    buildFormData(formData: FormData, data: any, parentKey?: any): void {
        if (
            data &&
            typeof data === 'object' &&
            !(data instanceof Date) &&
            !(data instanceof File)
        ) {
            Object.keys(data).forEach((key) => {
                this.buildFormData(
                    formData,
                    data[key],
                    parentKey ? `${parentKey}[${key}]` : key,
                );
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }
}
