import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@config/guards/auth.guard';
import { PublicGuard } from '@config/guards/public.guard';
import { InternalServerErrorComponent } from '@features/miscellaneous/internal-server-error/internal-server-error.component';
import { NotAuthorizedComponent } from '@features/miscellaneous/not-authorized/not-authorized.component';
import { NotFoundComponent } from '@features/miscellaneous/not-found/not-found.component';

const routes: Routes = [
    { path: '', redirectTo: '', pathMatch: 'full' },
    {
        path: '',
        loadChildren: () =>
            import('src/app/features/pages/pages-routing.module').then(
                (m) => m.PagesRoutingModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('src/app/features/auth/auth-routing.module').then(
                (m) => m.AuthRoutingModule,
            ),
        canActivate: [PublicGuard],
    },
    {
        path: 'public',
        loadChildren: () =>
            import('src/app/features/publics/publics-routing.module').then(
                (m) => m.PublicRoutingModule,
            ),
    },
    { path: '500', component: InternalServerErrorComponent },
    { path: '403', component: NotAuthorizedComponent },
    { path: '**', component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
