import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MiscellaneousComponent } from './miscellaneous.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

const routes: Routes = [
    {
        path: '',
        component: MiscellaneousComponent,
        children: [
            {
                path: '403',
                component: NotAuthorizedComponent,
            },
            {
                path: '404',
                component: NotFoundComponent,
            },
            {
                path: '500',
                component: InternalServerErrorComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MiscellaneousRoutingModule {}
