import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FilterHelper {
    handleFilter(
        data: Array<any>,
        filterKeys: string[],
        filterValue: string,
        sortKeys?: string[],
    ): any[] {
        const upperCaseFilterValue = filterValue.toUpperCase();
        const filteredData = data.filter((item) =>
            filterKeys.some((key) =>
                item?.[key]
                    ?.toString()
                    ?.toUpperCase()
                    ?.includes(upperCaseFilterValue),
            ),
        );

        if (!sortKeys || sortKeys.length === 0) {
            return filteredData;
        }

        return filteredData.sort((a, b) => {
            for (const sortKey of sortKeys) {
                const comparison = a[sortKey].localeCompare(b[sortKey]);
                if (comparison !== 0) {
                    return comparison;
                }
            }
            return 0;
        });
    }
}
