import { Injectable } from '@angular/core';
import { LOCAL_STORAGE } from '@config/local-storage.config';

@Injectable()
export class LocalStorageHelper {
    constructor() {}

    /**
     * set new element from local storage.
     * @param string key
     * @param any data
     */
    set(key: string, data: any): void {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.error('Error saving to localStorage', e);
        }
    }

    /**
     * get element from local storage.
     * @param string key
     */
    get(key: string): string | null {
        try {
            const data = localStorage.getItem(key);
            return data || null;
        } catch (e) {
            console.error('Error getting data from localStorage', e);
            return null;
        }
    }

    /**
     * get user role
     * @returns string
     */
    getUserRole(): string {
        const data = this.get(LOCAL_STORAGE.USER_INFO);
        return data ? JSON.parse(data).role : '';
    }

    /**
     * get userId
     * @returns string
     */
    getUserId(): string {
        const userId = this.get(LOCAL_STORAGE.USER_ID);
        return userId || '';
    }

    /**
     * Check if key exists in local storage
     * @param  string key
     * @return boolean
     */
    localStorageHas(key: string): boolean {
        const item = localStorage.getItem(key);
        return !!item;
    }

    /**
     * Remove element from local storage.
     * @param string key
     */
    remove(key: string): void {
        localStorage.removeItem(key);
    }
}
