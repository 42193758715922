import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalOptions } from 'ng-zorro-antd/modal/modal-types';

interface IConfirmModal {
    title?: string;
    content?: string;
    okFunc: () => any;
    cancelFunction?: () => any;
}
@Injectable()
export class ConfirmModalService {
    constructor(
        private modal: NzModalService,
        private translateService: TranslateService,
    ) {}

    /**
     * @description show confirm modal, default for delete
     */
    public showConfirm(data: IConfirmModal): void {
        const options = {
            nzTitle:
                data.title || this.translateService.instant('CONFIRM.DELETE'),
            nzContent: data.content || '',
            nzOnOk: data.okFunc,
        }  as ModalOptions;
        if (data.cancelFunction) {
            options.nzOnCancel = data.cancelFunction
        }
        this.modal.confirm(options);
    }
}
