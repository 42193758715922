import { Inject, Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
} from '@angular/common/http';

import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { filter, take, switchMap, catchError } from 'rxjs/operators';

import { AuthService } from '@features/auth/auth.service';
import { LOCAL_STORAGE } from '@config/local-storage.config';
import { AUTH_TOKEN_INTERCEPTOR_FILTER } from '@config/auth-token-filter.config';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);

    constructor(
        private authService: AuthService,
        @Inject(AUTH_TOKEN_INTERCEPTOR_FILTER) protected filterRequest: any,
    ) {}
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        // Upload file to S3 bucket [!(req.body instanceof File)] and except URL
        if (!this.filterRequest(request)) {
            if (!(request.body instanceof File)) {
                if (request.url.indexOf('refresh') !== -1) {
                    return next.handle(this.injectToken(request));
                }

                // nếu router là public thì không kiểm tra token
                if (window.location.pathname.split('/')?.[1] === 'public') {
                    return next.handle(request);
                }

                const accessExpired = this.authService.isAccessTokenExpired;
                const refreshExpired = this.authService.isRefreshTokenExpired;

                if (accessExpired && refreshExpired) {
                    this.authService.logout();
                    return next.handle(request);
                }
                if (accessExpired && !refreshExpired) {
                    if (!this.refreshTokenInProgress) {
                        this.refreshTokenInProgress = true;
                        this.refreshTokenSubject.next(null);
                        return this.authService.requestAccessToken().pipe(
                            switchMap((authResponse) => {
                                this.authService.saveToken(
                                    LOCAL_STORAGE.ACCESS_TOKEN,
                                    authResponse.accessToken,
                                );
                                this.authService.saveToken(
                                    LOCAL_STORAGE.REFRESH_TOKEN,
                                    authResponse.refreshToken,
                                );
                                this.refreshTokenInProgress = false;
                                this.refreshTokenSubject.next(
                                    authResponse.refreshToken,
                                );
                                return next.handle(this.injectToken(request));
                            }),
                            catchError(() => {
                                this.authService.logout();
                                return next.handle(request);
                            }),
                        );
                    } else {
                        return this.refreshTokenSubject.pipe(
                            filter((result) => result !== null),
                            take(1),
                            switchMap(() => {
                                return next.handle(this.injectToken(request));
                            }),
                        );
                    }
                }

                if (!accessExpired) {
                    return next.handle(this.injectToken(request));
                }

                return next.handle(request);
            } else {
                return next.handle(request);
            }
        } else {
            return next.handle(request);
        }

        // return next.handle(request).pipe(
        //   catchError((error: any, caught: Observable<any>): Observable<any> => {
        //     return error;
        //   })
        // );
    }

    injectToken(request: HttpRequest<any>): HttpRequest<any> {
        const token = this.authService.getToken;
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}
