// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    BE_URL: 'https://ihmqf5rru5.execute-api.ap-southeast-1.amazonaws.com/Prod/',
    RELOAD_ORDER_TIME: 600000, // 60s
    VNP_SECUREHASH: 'SLPIXUZVSVANJDGIHBDOICRMEXPNBJJP',
    ABOUT_DATE_NUMBER: 1,
    IMAGE_UPLOAD_NUMBER: 4,
    IMAGE_UPLOAD_CAMERA_NUMBER: 2,
    firebase: {
        apiKey: 'AIzaSyBGGpkO5qd9eEQDq7nP1dlZWCT__m-1Dbg',
        authDomain: 'cht-project-a6fe2.firebaseapp.com',
        projectId: 'cht-project-a6fe2',
        storageBucket: 'cht-project-a6fe2.appspot.com',
        messagingSenderId: '795039469811',
        appId: '1:795039469811:web:5692f9f799050b05be2786',
        measurementId: 'G-DNTJREXKKJ',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
